<template>
    <user-dashboard-layout>
        <v-container v-if="schedule.length > 0">
            <div class="day-columns">
                <div v-for="(name, day) in weekdays" :key="day">
                    <v-btn
                        color="white"
                        class="text-h6 text-capitalize"
                        x-large
                        block
                        elevation="0"
                        rounded-xl
                    >
                        {{ $t(name) }}
                    </v-btn>

                    <div class="hour-column">
                        <div class="pair-hour"
                             v-for="(hour, i) in schedule[day]"
                             :key="i">
                            <v-btn
                                v-if="hour.started_at.slice(hour.started_at.length - 2) === '30'"
                                @click="update(hour)"
                                :color="hour.available ? '#D8E6FC' : 'white'"
                                class="text-h6 py-1 font-weight-regular"
                                block
                                elevation="0"
                                rounded-lg
                            >
                                {{ hour.started_at }}
                            </v-btn>
                            <v-btn
                                v-if="hour.started_at.slice(hour.started_at.length - 2) === '00'"
                                @click="update(hour)"
                                :color="hour.available ? '#D8E6FC' : 'white'"
                                class="text-h6 py-1 font-weight-regular"
                                block
                                elevation="0"
                                rounded-lg
                            >
                                {{ hour.started_at }}
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
            <div class="black--text text-h6 font-weight-bold mt-4">{{ $t('general.schedule-vending-caption') }}</div>
            <div class="day-columns mt-4">
                <div v-for="(name, day) in weekdays" :key="day">
                    <v-btn
                        color="white"
                        class="text-h6 text-capitalize"
                        x-large
                        block
                        elevation="0"
                        rounded-xl
                    >
                        {{ $t(name) }}
                    </v-btn>
                    <v-select
                        v-model="type[day]"
                        :items="types"
                        item-text="text"
                        item-value="value"
                        @change="updateVendingType(day)"
                    >
                    </v-select>
                    <div class="hour-column" v-if="type[day] === 0">
                        <div class="pair-hour"
                             v-for="(hour, i) in getScheduleVending(day)"
                             :key="i + '_vending'">
                            <v-btn
                                v-if="hour.started_at.slice(hour.started_at.length - 2) === '30'"
                                @click="updateVending(hour)"
                                :color="hour.available ? '#D8E6FC' : 'white'"
                                class="text-h6 py-1 font-weight-regular"
                                block
                                elevation="0"
                                rounded-lg
                            >
                                {{ hour.started_at }}
                            </v-btn>
                            <v-btn
                                v-if="hour.started_at.slice(hour.started_at.length - 2) === '00'"
                                @click="updateVending(hour)"
                                :color="hour.available ? '#D8E6FC' : 'white'"
                                class="text-h6 py-1 font-weight-regular"
                                block
                                elevation="0"
                                rounded-lg
                            >
                                {{ hour.started_at }}
                            </v-btn>
                        </div>
                    </div>
                    <div v-else>
                        <v-text-field
                            :label="$t('general.from')"
                            placeholder="00:00"
                            :rules="[rules.required]"
                            variant="underlined"
                            v-mask="'##:##'"
                            v-model="mapRange[day].from"
                        ></v-text-field>
                        <v-text-field
                            :label="$t('general.to')"
                            placeholder="00:00"
                            :rules="[rules.required]"
                            variant="underlined"
                            v-mask="'##:##'"
                            v-model="mapRange[day].to"
                        ></v-text-field>
                        <v-checkbox :label="$t('general.available')"
                                    v-model="mapRange[day].available"></v-checkbox>
                        <v-btn outlined
                               width="100%"
                               small
                               color="primary"
                               :loading="mapRange[day].loading"
                               :disabled="mapRange[day].loading"
                               @click="updateRange(day)">{{ $t('general.save') }}
                        </v-btn>
                    </div>
                </div>
            </div>
        </v-container>
        <v-row v-else>
            <v-col>
                <v-row class="d-flex justify-center">
                    <v-icon
                        large
                        class="spinner-icon"
                        color="blue-grey darken-2"
                    >
                        mdi-autorenew
                    </v-icon>
                </v-row>
            </v-col>
        </v-row>
    </user-dashboard-layout>
</template>

<script>
import UserDashboardLayout from "../../../layouts/UserDashboardLayout";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "dashboard-index",
    components: {UserDashboardLayout},
    data() {
        return {
            days: [],
            types: [
                {
                    text: this.$t('general.point'),
                    value: 0,
                },
                {
                    text: this.$t('general.range'),
                    value: 1,
                },
            ],
            mapRange: [
                {
                    from: '',
                    to: '',
                    available: false,
                    loading: false,
                },
                {
                    from: '',
                    to: '',
                    available: false,
                    loading: false,
                },
                {
                    from: '',
                    to: '',
                    available: false,
                    loading: false,
                },
                {
                    from: '',
                    to: '',
                    available: false,
                    loading: false,
                },
                {
                    from: '',
                    to: '',
                    available: false,
                    loading: false,
                },
                {
                    from: '',
                    to: '',
                    available: false,
                    loading: false,
                },
                {
                    from: '',
                    to: '',
                    available: false,
                    loading: false,
                },
                {
                    from: '',
                    to: '',
                    available: false,
                    loading: false,
                },
            ],
            rules: {
                required: value => !!value || 'Required.',
            },
        };
    },
    computed: {
        ...mapGetters(["schedule", "scheduleVending", "type"])
    },
    methods: {
        ...mapActions([
            "setSchedule",
            "updateSchedule",
            "storeSchedule",
            // vending
            "setScheduleVending",
            "updateTypeVending",
            "updateScheduleVending",
            "updateRangeVending",
        ]),
        getScheduleVending(day) {
            if (this.scheduleVending[day]) {
                return this.scheduleVending[day].filter(fn => fn.is_range === false);
            }
            return [];
        },
        update(hour) {
            hour.available = !hour.available;
            this.updateSchedule(hour);
        },
        updateVending(hour) {
            hour.available = !hour.available;
            this.updateScheduleVending(hour);
        },
        async updateVendingType(day) {
            let payload = {
                day: day,
                value: this.type[day],
            };
            await this.updateTypeVending(payload);
            this.fillVendingRange();
        },
        async updateRange(day) {
            let payload = this.mapRange[day];
            if (payload.from && payload.to) {
                payload.loading = true;
                payload.day = day;
                try {
                    await this.updateRangeVending(payload);
                } finally {
                    payload.loading = false;
                }
            }
        },
        fillVendingRange() {
            this.type.forEach((t, index) => {
                if (t > 0 && this.scheduleVending[index]) {
                    let item = this.scheduleVending[index].find(elem => elem.is_range === true);
                    if (item) {
                        this.mapRange[index].from = item.from_at;
                        this.mapRange[index].to = item.end_at;
                        this.mapRange[index].available = item.available;
                    }
                }
            })
        },
    },
    async created() {
        this.weekdays = ["general.mon", "general.tue", "general.wed", "general.thu", "general.fri", "general.sat", "general.sun"];
        await this.setSchedule();
        await this.setScheduleVending();
        this.fillVendingRange();
    }
};
</script>

<style scoped>
.day-columns {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 15px;
}

/*.hour-column {
    display: grid;
    gap: 6px;
    margin-top: 18px;
}*/

.spinner-icon {
    transform: rotate(360deg);
    animation: linear spin 5s infinite;
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.hour-column {

    /*    display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;*/
    margin-top: 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.pair-hour {
    width: calc(50% - 6px);
    box-sizing: border-box;
    padding: 0;
    margin-bottom: 10px;
}

.pair-hour button {
    max-width: 100%;
    box-sizing: border-box;
    padding: 0;
}
</style>
